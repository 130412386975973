import { Link } from "react-router";

export function NotFound() {
  return (
    <div className="min-h-screen flex items-center justify-center bg-white">
      <div className="text-center px-4 max-w-xl mx-auto">
        <div className="mb-12">
          <img src="/images/Condoit-Color-Logo-1.png" alt="Condoit" className="h-16 mx-auto" />
        </div>

        <div className="space-y-6">
          <h1 className="text-[#F73133] text-7xl font-bold">404</h1>

          <div className="space-y-4">
            <h2 className="text-2xl font-medium text-gray-900">Page Not Found</h2>
            <p className="text-lg text-gray-700">The page you're looking for doesn't exist or has been moved.</p>
          </div>

          <div className="mt-8">
            <Link
              onClick={() => (window.location.href = "/projects")}
              className="inline-flex items-center gap-2 text-[#F73133] text-lg font-medium hover:text-[#C92929] transition-colors"
            >
              <span aria-hidden="true">←</span>
              <span>Back to Home</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
