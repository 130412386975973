import { captureRemixErrorBoundaryError } from "@sentry/remix";
import { isRouteErrorResponse, useRouteError } from "react-router";
import { NotFound } from "../NotFound";

export default function SomethingWentWrong({
  error,
  errorMessage,
  reset,
  shouldAllowRetry = true,
}: {
  error: (Error & { digest?: string }) | null;
  errorMessage?: string;
  reset?: () => void;
  shouldAllowRetry?: boolean;
}) {
  return (
    <div className="p-4 max-w-3xl mx-auto">
      <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded" role="alert">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <title>Error icon</title>
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <div className="ml-3">
            <p className="text-sm text-red-700">
              {errorMessage ?? "Sorry, something went wrong. Please try again later."}
            </p>
            <p className="mt-2 text-sm text-red-700">
              If this problem persists, contact us at{" "}
              <a href="mailto:support@condoit.io" className="font-medium underline">
                support@condoit.io
              </a>
            </p>
          </div>
        </div>
      </div>

      {shouldAllowRetry && reset && (
        <button
          onClick={() => reset?.()}
          type="button"
          className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          Retry
        </button>
      )}
    </div>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  // Handle 404s
  if (isRouteErrorResponse(error) && error.status === 404) {
    return <NotFound />;
  }

  // Handle other route errors
  if (isRouteErrorResponse(error)) {
    return (
      <SomethingWentWrong
        error={null}
        errorMessage={`${error.status} Error: ${error.data?.message || "An error occurred"}`}
        shouldAllowRetry={false}
      />
    );
  }

  // Handle general errors
  return (
    <SomethingWentWrong
      error={error as Error}
      errorMessage={error instanceof Error ? error.message : "An unexpected error occurred"}
    />
  );
}
